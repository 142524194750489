const mobileMenu = () => {
    const openMenu = () => {
        const mobileMenu = document.querySelector('#mobile-menu');
        mobileMenu.style.left = '0';
    }

    const closeMenu = () => {
        const mobileMenu = document.querySelector('#mobile-menu');
        mobileMenu.style.left = '-100%';
    }

    const hamburgerButton = document.querySelector('#hamburger-button');
    const closeButton = document.querySelector('#close-button');

    hamburgerButton.addEventListener('click', openMenu);
    closeButton.addEventListener('click', closeMenu);

    // Listen for clicks on the whole document
    document.addEventListener('click', function(event) {
        const mobileMenu = document.querySelector('#mobile-menu');

        // If the click was not on the mobile menu, or the open button, close the menu
        if (!mobileMenu.contains(event.target) && !hamburgerButton.contains(event.target)) {
            closeMenu();
        }
    });
}

export default mobileMenu;
