const fadeNavigation = () => {
    let links = document.querySelectorAll('.transition-link');

    links.forEach(function (link) {
        link.addEventListener('click', function (event) {
            let href = link.getAttribute('href');

            // If the href attribute does not contain a hashtag or 'javascript:'
            if (href && !href.startsWith('#') && !href.startsWith('javascript:')) {
                event.preventDefault();
                document.body.classList.add('fade-out');
                setTimeout(function () {
                    window.location.href = href;
                }, 200);
            }
        });
    });
}

export default fadeNavigation;
