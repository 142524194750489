const stickyHeader = () => {
  let lastScrollTop = 0;  // We start at the top of the page
  const delta = 5;  // This is the scroll threshold (in px)
  const header = document.querySelector('.header');  // The header element

  window.addEventListener('scroll', () => {
    let currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // If we scrolled up (and we're not just at the top of the page), show the header
    if (currentScrollTop < lastScrollTop && currentScrollTop > delta) {
      header.classList.remove('header--hidden');
    // Else if we scrolled down past the threshold, hide the header
    } else if (currentScrollTop - lastScrollTop > delta) {
      header.classList.add('header--hidden');
    }

    lastScrollTop = currentScrollTop;
  });
}

export default stickyHeader;
