import mobileMenu from '../modules/mobile-menu';
import stickyMenu from '../modules/sticky-menu';
import fadeNavigation from '../modules/fade-navigation';

export default {
    init() {
        mobileMenu();
        stickyMenu();

        const categoryBoxesContainer = document.querySelector('.course-category-boxes__container');

        if (categoryBoxesContainer) {
            const categoryBoxes = categoryBoxesContainer.querySelectorAll('.course-category-box');

            categoryBoxes.forEach(box => {
                box.addEventListener('mouseover', () => {
                    categoryBoxes.forEach(item => {
                        item.classList.remove('course-category-box--active');
                    });
                });
            });
        }

    },
    finalize() {
        // JavaScript to be fired on the homeTemplate page, after the init JS
        fadeNavigation();
    },
};
